import React from 'react'
import { Link } from 'gatsby'

import './style.css'

const ResidentialList = (props) => {
  return <section className="residential-list">
    {props.list.map( item => {
      return ResidentialItem(item)
    })}
  </section>
}

const ResidentialItem = (props) => {
  return <article key={props.imageSource} className="residential-item">
    <Link to={props.link}>
      <article>
        <div>
          <p>{props.residenceTitle}</p>
          <p>{props.residenceLocation}</p>
        </div>
      </article>
      <img src={props.imageSource} alt={props.residenceTitle}></img>
    </Link>
  </article>
}

export default ResidentialList;

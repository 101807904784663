import React from 'react';

import Layout from '../../components/layout';
import Container from '../../components/container';
import ResidentialList from '../../components/residential-list';

import Photo1 from '../../images/work/01_PB_MATADOR/PHOTOS/450X280/MA-SM_01.jpg';
import Photo2 from '../../images/work/02_PB_MID_COAST/PHOTOS/450X280/MI-SM_01.jpg';
import Photo3 from '../../images/work/03_PB_ALDRICH/PHOTOS/450X280/AL-SM_01.jpg';
import Photo4 from '../../images/work/04_PG_MATTHAMS/PHOTOS/450x280/MA-SM_01.jpg';
import Photo5 from '../../images/work/05_CARMEL_PRICE/PHOTOS/450X280/PR-SM_01.jpg';
// import Photo6 from '../../images/work/06_CARMEL_BARON/PHOTOS/450X280/BA-SM_01.jpg';
// import Photo7 from '../../images/work/07_PB_MEYER_RIATA/PHOTOS/450X280/ME-SM_01.jpg';
import Photo8 from '../../images/work/08_PB_WALKER/PHOTOS/450x280/WA-SM_01.jpg';
import Photo9 from '../../images/work/09_PG_SYIDA/PHOTOS/450X280/SY-SM_01.jpg';
// import Photo10 from '../../images/work/10_CARMEL_BARBER_AND_MUELLER/PHOTOS/450X280/B&M-SM_01.jpg';
// import Photo11 from '../../images/work/11_CARMEL_STUDIO_LUCHETTI/PROCESS/450X280/STU-SM_01.jpg';
import Photo12 from '../../images/work/12_PG_HILLCREST/PROCESS/450x280/HIL-SM_01.jpg';
import Photo13 from '../../images/work/13_SALINAS_HOLADAY/PROCESS/450x280/HOL-SM_01.jpg';
// import Photo14 from '../../images/work/14_CARMEL_CRICHTON/PHOTOS/450X280/CR-SM_01.jpg';
import Photo15 from '../../images/work/15_PB_SURI/PHOTOS/450x280/SU-SM_01.jpg';
import Photo16 from '../../images/work/16_PB_MARINA_OLEADA/PROCESS/450x280/MAR-SM_01.jpg';
import Photo17 from '../../images/work/17_CARMEL_VALLEY_SCHUMACHER/PHOTOS/450x280/SC-SM_01.jpg';
import Photo18 from '../../images/work/18_PB_WILDCAT/PROCESS/450x280/WIL-SM_01.jpg';
import Photo19 from '../../images/work/19_PB_PORTOLA/PHOTOS/450x280/PO-SM_01.jpg';
// import Photo20 from '../../images/work/20_SALINAS_RIMROCK/PROCESS/450x280/RIM-SM_01.jpg';
import Photo21 from '../../images/work/21_MONTEREY_PINE/PROCESS/450x280/PIN-SM_01.jpg';
import Photo22 from '../../images/work/22_CASA_LA_DONA/PROCESS/450x280/CASA_SM_01.jpg';
import Photo23 from '../../images/work/23_THE_WAVE/PROCESS/450x280/WAVE_SM_01.jpg';
import Photo24 from '../../images/work/24_MID_CENTURY_MODERN/PROCESS/450x280/BROWN_SM_04.jpg';

import './style.css';

const RESIDENTIAL_PLACES = [
  {
    link: '/work/22',
    imageSource: Photo22,
    residenceTitle: 'CASA LA DONA',
    residenceLocation: 'PEBBLE BEACH, CALIFORNIA',
  },
  {
    link: '/work/23',
    imageSource: Photo23,
    residenceTitle: 'THE WAVE',
    residenceLocation: 'PEBBLE BEACH, CALIFORNIA',
  },
  {
    link: '/work/24',
    imageSource: Photo24,
    residenceTitle: 'MID-CENTURY MODERN',
    residenceLocation: 'CARMEL, CALIFORNIA',
  },
  {
    link: '/work/01',
    imageSource: Photo1,
    residenceTitle: 'OLD SPANISH',
    residenceLocation: 'PEBBLE BEACH, CALIFORNIA',
  },
  {
    link: '/work/02',
    imageSource: Photo2,
    residenceTitle: 'MEDITERRANEAN REVIVAL',
    residenceLocation: 'PEBBLE BEACH, CALIFORNIA',
  },
  {
    link: '/work/03',
    imageSource: Photo3,
    residenceTitle: 'THE ALDRICH RESIDENCE',
    residenceLocation: 'PEBBLE BEACH, CALIFORNIA',
  },
  {
    link: '/work/04',
    imageSource: Photo4,
    residenceTitle: 'THE MATTHAMS RESIDENCE',
    residenceLocation: 'PACIFIC GROVE, CALIFORNIA',
  },
  {
    link: '/work/05',
    imageSource: Photo5,
    residenceTitle: "MUSICIAN'S RETREAT",
    residenceLocation: 'CARMEL, CALIFORNIA',
  },
  {
    link: '/work/15',
    imageSource: Photo15,
    residenceTitle: 'SPANISH ECLECTIC',
    residenceLocation: 'PEBBLE BEACH, CALIFORNIA',
  },
  {
    link: '/work/19',
    imageSource: Photo19,
    residenceTitle: 'PEBBLE BEACH RESIDENCE',
    residenceLocation: 'PEBBLE BEACH, CALIFORNIA',
  },
  {
    link: '/work/08',
    imageSource: Photo8,
    residenceTitle: 'PEBBLE BEACH RESIDENCE',
    residenceLocation: 'PEBBLE BEACH, CALIFORNIA',
  },
  {
    link: '/work/09',
    imageSource: Photo9,
    residenceTitle: 'PACIFIC GROVE RESIDENCE',
    residenceLocation: 'PACIFIC GROVE, CALIFORNIA',
  },
  {
    link: '/work/17',
    imageSource: Photo17,
    residenceTitle: 'RANCH HOUSE',
    residenceLocation: 'CARMEL VALLEY, CALIFORNIA',
  },
  {
    link: '/work/12',
    imageSource: Photo12,
    residenceTitle: 'PACIFIC GROVE RESIDENCE',
    residenceLocation: 'PACIFIC GROVE, CALIFORNIA',
  },
  {
    link: '/work/13',
    imageSource: Photo13,
    residenceTitle: 'OUTDOOR IMPROVEMENTS',
    residenceLocation: 'SALINAS, CALIFORNIA',
  },
  // {
  //   link: '/work/14',
  //   imageSource: Photo14,
  //   residenceTitle: 'CARMEL COTTAGE',
  //   residenceLocation: 'CARMEL-BY-THE-SEA, CALIFORNIA',
  // },
  {
    link: '/work/16',
    imageSource: Photo16,
    residenceTitle: 'PEBBLE BEACH RESIDENCE',
    residenceLocation: 'PEBBLE BEACH, CALIFORNIA',
  },
  {
    link: '/work/18',
    imageSource: Photo18,
    residenceTitle: 'WILDCAT RESIDENCE',
    residenceLocation: 'PEBBLE BEACH, CALIFORNIA',
  },
  {
    link: '/work/21',
    imageSource: Photo21,
    residenceTitle: 'PINE RESIDENCE',
    residenceLocation: 'MONTEREY, CALIFORNIA',
  },
];

const WorkPage = () => (
  <Layout>
    <Container>
      <section className="work-page">
        {ResidentialList({ list: RESIDENTIAL_PLACES })}
      </section>
    </Container>
  </Layout>
);

export default WorkPage;
